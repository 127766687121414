$color-blue: var(--color-1);
$color-gray: #dfe0e4;
$color-white: #ffffff;
$slider-handle-size: 20px;
$slider-size: 4px;
$slider-fix: 20px;

.m-calendar {
  display: inline-block;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
  }

  td {
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    color: $color-gray;
    border: 1px solid $color-gray;
  }

  thead {
    td {
      color: $color-blue;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  tbody td {
    color: #666666;

    &:hover {
      background: $color-blue;
      border-color: $color-blue;
      color: $color-white;
    }
  }

  .current-day {
    color: $color-blue;
    font-weight: bold;
  }

  .prev-month,
  .next-month {
    color: #999999;
  }

  .toolbar {
    line-height: 30px;
    color: $color-blue;
    text-align: center;
    margin-bottom: 13px;

    button {
      position: relative;
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: #ffffff;
      border: 1px solid $color-blue;
      border-radius: 50%;
      background: $color-blue;
      font-size: 20px;
      padding: 0;
      text-align: center;
      outline: 0;
      z-index: 5;
      cursor: pointer;
    }

    .prev-month {
      float: left;
    }

    .next-month {
      float: right;
    }

    .current-date {
      color: $color-blue;
    }
  }
}

.m-input-moment {
  border: 0;
}

.m-input-moment__btn-options {
  width: 44%;
  color: var(--color-1);
  border: 1px solid var(--color-1);
}

.m-input-moment__btn-options.is-active {
  color: #fff;
  background-color: var(--color-1);
}

.m-calendar__toolbar {
  color: var(--color-1);
}

.m-calendar__btn {
  border: 1px solid var(--color-1);
  background: var(--color-1);
}

.m-calendar__current-date {
  color: var(--color-1);
}

.m-input-moment__tab {
  height: 265px;
}

.m-calendar thead td {
  color: var(--color-1);
}

.m-calendar .day--active:hover {
  background: var(--color-1);
  border-color: var(--color-1);
  color: #fff;
}

.m-calendar .day--selected {
  background: var(--color-1);
  color: #fff;
}

.m-calendar tbody td {
  color: var(--color-text);
}

.m-input-moment__btn-save {
  background-color: var(--color-1);
}

.m-calendar td {
  border: 1px solid var(--color-separator);
}
