.preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  overflow: hidden;

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 999;
    &.abs {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.background {
      opacity: 0.7;
      background-color: var(--color-separator);
    }
  }
}
