.sidebar{
  &__link {
    font-weight: 100;
    display: flex;
    width: 100%;
    padding-left: 40px;
    color: #eee;
    &.active {
      font-weight: 400;
      color: var(--color-1);
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: var(--color-1);
        top: 0;
        left: 30px;
      }
    }
  }
  &__link-main {
    color: #eee;
    &.active {
      font-weight: 400;
      color: var(--color-1);
    }
  }
  &__version {
    line-height: 1.4em;
    width: 100%;
    color: #bdbdbd;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    padding: 4px;
  }
}