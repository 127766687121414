.react-json-view .copy-to-clipboard-container {
  vertical-align: top;
  height: 14px;
  display: inline-block;
  opacity: 0;
}

.react-json-view .object-content .variable-row:hover .click-to-edit,
.react-json-view .object-content .variable-row:hover .click-to-remove, 
.react-json-view .object-key-val:hover>span>.object-meta-data>.click-to-add,
.react-json-view .object-key-val:hover>span>.object-meta-data>.click-to-remove,
.react-json-view .object-key-val:hover>span>.object-meta-data>.copy-to-clipboard-container,
.react-json-view .variable-row:hover .copy-to-clipboard-container {
  display: inline-block;
  opacity: 1;
}