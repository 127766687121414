.data-table {
  th {
    height: auto !important;
    max-height: 42px;
    svg {
      margin-right: 0 !important;
      flex-shrink: 0;
    }
  }
}
