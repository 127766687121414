.preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  overflow: hidden; }
  .preloader__container {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 999; }
    .preloader__container.abs {
      position: absolute;
      top: 0;
      left: 0; }
    .preloader__container.background {
      opacity: 0.7;
      background-color: var(--color-separator); }

.data-table th {
  height: auto !important;
  max-height: 42px; }
  .data-table th svg {
    margin-right: 0 !important;
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }

.data-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  padding: 10px 15px; }
  .data-header__title {
    font-size: 18px;
    line-height: 26px;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .data-header__actions {
    margin-left: auto;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .data-header__content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: stretch;
            align-content: stretch;
    width: 90%;
    -webkit-justify-content: center;
            justify-content: center; }

.sidebar__link {
  font-weight: 100;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding-left: 40px;
  color: #eee; }
  .sidebar__link.active {
    font-weight: 400;
    color: var(--color-1); }
    .sidebar__link.active:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: var(--color-1);
      top: 0;
      left: 30px; }

.sidebar__link-main {
  color: #eee; }
  .sidebar__link-main.active {
    font-weight: 400;
    color: var(--color-1); }

.sidebar__version {
  line-height: 1.4em;
  width: 100%;
  color: #bdbdbd;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: 10px;
  padding: 4px; }


.m-calendar {
  display: inline-block; }
  .m-calendar table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed; }
  .m-calendar td {
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    color: #dfe0e4;
    border: 1px solid #dfe0e4; }
  .m-calendar thead td {
    color: var(--color-1);
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px; }
  .m-calendar tbody td {
    color: #666666; }
    .m-calendar tbody td:hover {
      background: var(--color-1);
      border-color: var(--color-1);
      color: #ffffff; }
  .m-calendar .current-day {
    color: var(--color-1);
    font-weight: bold; }
  .m-calendar .prev-month,
  .m-calendar .next-month {
    color: #999999; }
  .m-calendar .toolbar {
    line-height: 30px;
    color: var(--color-1);
    text-align: center;
    margin-bottom: 13px; }
    .m-calendar .toolbar button {
      position: relative;
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: #ffffff;
      border: 1px solid var(--color-1);
      border-radius: 50%;
      background: var(--color-1);
      font-size: 20px;
      padding: 0;
      text-align: center;
      outline: 0;
      z-index: 5;
      cursor: pointer; }
    .m-calendar .toolbar .prev-month {
      float: left; }
    .m-calendar .toolbar .next-month {
      float: right; }
    .m-calendar .toolbar .current-date {
      color: var(--color-1); }

.m-input-moment {
  border: 0; }

.m-input-moment__btn-options {
  width: 44%;
  color: var(--color-1);
  border: 1px solid var(--color-1); }

.m-input-moment__btn-options.is-active {
  color: #fff;
  background-color: var(--color-1); }

.m-calendar__toolbar {
  color: var(--color-1); }

.m-calendar__btn {
  border: 1px solid var(--color-1);
  background: var(--color-1); }

.m-calendar__current-date {
  color: var(--color-1); }

.m-input-moment__tab {
  height: 265px; }

.m-calendar thead td {
  color: var(--color-1); }

.m-calendar .day--active:hover {
  background: var(--color-1);
  border-color: var(--color-1);
  color: #fff; }

.m-calendar .day--selected {
  background: var(--color-1);
  color: #fff; }

.m-calendar tbody td {
  color: var(--color-text); }

.m-input-moment__btn-save {
  background-color: var(--color-1); }

.m-calendar td {
  border: 1px solid var(--color-separator); }

.m-time {
  color: #ffffff;
  padding-top: 50px; }
  .m-time .showtime {
    text-align: center; }
  .m-time .separater {
    display: inline-block;
    font-size: 32px;
    font-weight: bold;
    color: var(--color-1);
    width: 32px;
    height: 65px;
    line-height: 65px;
    text-align: center; }
  .m-time .time-text {
    position: relative;
    left: -10px;
    font-size: 15px;
    color: var(--color-1);
    margin-top: 7px;
    margin-bottom: 10px; }
  .m-time .time-header-text {
    position: relative;
    left: 15%;
    top: -30px;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--color-1);
    margin-top: 7px;
    margin-bottom: 50px;
    padding-bottom: 50px;
    text-align: center; }
  .m-time .sliders {
    padding: 0 10px; }
  .m-time .time {
    width: 65px;
    height: 65px;
    display: inline-block;
    font-size: 38px;
    line-height: 65px;
    background-color: var(--color-1);
    border-radius: 3px;
    text-align: center;
    border: 0;
    color: #ffffff; }

.u-slider-time {
  position: relative;
  display: inline-block;
  background-color: #dfe0e4;
  border-radius: 3px;
  height: 4px;
  width: 100%;
  cursor: pointer; }
  .u-slider-time .value {
    position: absolute;
    background-color: var(--color-1);
    border-radius: 3px;
    top: 0;
    height: 100%; }
  .u-slider-time .handle {
    position: absolute;
    width: 4px;
    height: 4px; }
    .u-slider-time .handle:after {
      position: relative;
      display: block;
      content: '';
      top: -10px;
      left: -12px;
      width: 24px;
      height: 24px;
      background-color: #fff;
      border: 3px solid var(--color-1);
      border-radius: 50%;
      cursor: pointer; }


.copy-link {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted transparent;
  cursor: pointer;
  padding: 0;
  margin: 0 10px; }
  .copy-link:before {
    content: '';
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 20px;
    background: transparent;
    z-index: 100; }
  .copy-link:hover {
    border-bottom: 1px dotted var(--color-1);
    color: var(--color-1);
    z-index: 100; }
    .copy-link:hover:before {
      display: block; }




.header {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
  -webkit-transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  padding-right: 56px; }
  .header.fixed {
    position: fixed;
    width: 100%; }
    .header.fixed.open {
      width: -webkit-calc(100% - 230px);
      width: calc(100% - 230px); }
  .header__fake {
    width: 100%;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto; }
  .header__title {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 8px;
    gap: 8px;
    overflow: auto; }
  .header__separator {
    background-color: var(--color-1-light);
    height: 32px;
    margin: 0px 5px;
    width: 1px;
    line-height: 56px;
    display: inline-block;
    vertical-align: middle; }

.color__radio:not(:last-child) {
  margin-bottom: 15px; }

.react-json-view .copy-to-clipboard-container {
  vertical-align: top;
  height: 14px;
  display: inline-block;
  opacity: 0; }

.react-json-view .object-content .variable-row:hover .click-to-edit,
.react-json-view .object-content .variable-row:hover .click-to-remove,
.react-json-view .object-key-val:hover > span > .object-meta-data > .click-to-add,
.react-json-view .object-key-val:hover > span > .object-meta-data > .click-to-remove,
.react-json-view .object-key-val:hover > span > .object-meta-data > .copy-to-clipboard-container,
.react-json-view .variable-row:hover .copy-to-clipboard-container {
  display: inline-block;
  opacity: 1; }

.help-button {
  bottom: 0;
  right: 0;
  height: 56px;
  width: 56px;
  z-index: 1195;
  padding: 5px; }

.widget__mobile-panel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  padding-top: 65px; }

.widget__panel {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 56px;
  height: -webkit-calc(100% - 56px);
  height: calc(100% - 56px);
  background-color: var(--color-background-f);
  z-index: 1299; }

.widget__button:hover {
  background: var(--color-1-light); }

.widget__button.animate {
  -webkit-animation: animate 2.5s infinite ease-in-out;
          animation: animate 2.5s infinite ease-in-out; }

@-webkit-keyframes animate {
  0% {
    background: none; }
  50% {
    background: var(--color-1-light); }
  100% {
    background: none; } }

@keyframes animate {
  0% {
    background: none; }
  50% {
    background: var(--color-1-light); }
  100% {
    background: none; } }

@-webkit-keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__outerAnimating {
  -webkit-animation-name: closeWindow;
          animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}


.login {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0.9; }
  .login__card {
    min-width: 300px;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transition: all .4s ease-in-out !important;
    transition: all .4s ease-in-out !important;
    -webkit-transform: translate(-150px, -154px);
            transform: translate(-150px, -154px); }
    .login__card--show {
      -webkit-transform: translate(-150px, -184px);
              transform: translate(-150px, -184px);
      opacity: .75; }
  .login__logo {
    width: 300px;
    padding: 10px 0 20px; }
  .login__form {
    padding: 0 1em 1em 1em; }
  .login__input {
    display: -webkit-flex;
    display: flex; }
    .login__input input:-webkit-autofill {
      color: var(--color-text);
      -webkit-text-fill-color: var(--color-text); }
    .login__input input:-webkit-autofill:hover {
      color: var(--color-text);
      -webkit-text-fill-color: var(--color-text); }
  .login__version {
    padding: 5px 10px 10px;
    font-size: 11px;
    color: var(--color-text);
    opacity: 0.9; }
    .login__version span {
      float: right; }

.dot {
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 2; }

html,
body,
#container {
  width: 100%;
  height: 100%; }

#container {
  position: absolute;
  top: 0; }

.app-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  position: relative;
  -webkit-transition: margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .app-container.open {
    margin-left: 230px; }

.route-wrapper {
  position: relative; }

.route-wrapper > div {
  position: absolute; }

main {
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: hidden; }

.main {
  overflow: auto;
  height: 100%; }

body::-webkit-scrollbar {
  display: none; }

.wrapper {
  height: 100%;
  opacity: 1;
  padding: 10px;
  -webkit-transition: opacity 0.25s ease 0.2s, -webkit-transform 0.25s ease 0.2s;
  transition: opacity 0.25s ease 0.2s, -webkit-transform 0.25s ease 0.2s;
  transition: transform 0.25s ease 0.2s, opacity 0.25s ease 0.2s;
  transition: transform 0.25s ease 0.2s, opacity 0.25s ease 0.2s, -webkit-transform 0.25s ease 0.2s; }
  .wrapper.anim-exit {
    -webkit-transition: opacity 0.25s ease 0s, -webkit-transform 0.25s ease 0s;
    transition: opacity 0.25s ease 0s, -webkit-transform 0.25s ease 0s;
    transition: transform 0.25s ease 0s, opacity 0.25s ease 0s;
    transition: transform 0.25s ease 0s, opacity 0.25s ease 0s, -webkit-transform 0.25s ease 0s;
    -webkit-transform: translate3d(0, 15px, 0px);
            transform: translate3d(0, 15px, 0px);
    opacity: 0; }
  .wrapper.anim-enter {
    position: absolute;
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0px);
            transform: translate3d(0, 15px, 0px); }

.clippy {
  position: absolute;
  z-index: 999999; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

[role='button'],
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  box-sizing: content-box; }

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none; }

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0; }

[role='button'] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

button:active {
  outline: none;
  border: none; }

button:focus {
  outline: 0; }

textarea:focus,
input:focus {
  outline: none; }

a {
  text-decoration: none;
  color: inherit; }

input,
select {
  border-radius: 0; }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto"), url(/static/media/Roboto-Light.7b5fb88f.ttf); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/Roboto-Regular.ac3f799d.ttf); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/Roboto-Medium.fe13e417.ttf); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Roboto-Bold.d329cc8b.ttf); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/Roboto-Black.893fe146.ttf); }

* {
  box-sizing: border-box; }

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: none;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

:root {
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300; }

#root,
html {
  height: 100%; }

body {
  font-size: 1rem; }

html,
body {
  height: 100%;
  background-color: var(--color-2);
  color: var(--color-text); }

h1 {
  font-weight: 500;
  font-size: 2.4rem; }

[hidden] {
  display: none !important; }

pre::-webkit-scrollbar,
textarea::-webkit-scrollbar,
nav::-webkit-scrollbar,
ul::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

pre::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
nav::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background: transparent; }

pre::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
nav::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background: var(--color-separator);
  border: 1px solid #8c8c8c73;
  border-radius: 2px;
  margin-right: 1px; }
  pre::-webkit-scrollbar-thumb:hover,
  textarea::-webkit-scrollbar-thumb:hover,
  nav::-webkit-scrollbar-thumb:hover,
  ul::-webkit-scrollbar-thumb:hover,
  div::-webkit-scrollbar-thumb:hover {
    background: var(--color-1-light); }

.link {
  text-decoration: underline;
  color: var(--color-1);
  cursor: pointer; }
  .link:hover {
    color: var(--color-1-light); }

.ReactModal__Overlay {
  z-index: 1900 !important; }

.error {
  padding: 15px;
  margin: 10px;
  background-color: #ff000038;
  border-radius: 3px;
  width: 350px; }
  .error p {
    padding: 0 0 10px 0;
    line-height: 1.2em; }

.widget__error {
  height: 55px;
  padding: 15px;
  background-color: #ff57575e;
  cursor: pointer; }

.widget__button {
  position: relative;
  height: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

strong {
  font-weight: 600; }

[data-tooltip] {
  position: relative;
  cursor: help; }

[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap; }

[data-tooltip]:hover::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid #000; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
input:-internal-autofill-selected {
  color: var(--color-text) !important;
  -webkit-text-fill-color: var(--color-text) !important; }

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }
  .animated.bounceIn {
    -webkit-animation-duration: .7s;
            animation-duration: .7s; }
  .animated.bounceOut {
    -webkit-animation-duration: .5s;
            animation-duration: .5s; }
  .animated.bounceIn {
    -webkit-animation-name: bounceIn;
            animation-name: bounceIn; }
  .animated.bounceOut {
    -webkit-animation-name: bounceOut;
            animation-name: bounceOut; }
  .animated.fadeIn {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: .7s;
            animation-duration: .7s; }
  .animated.fadeOut {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-duration: .3s;
            animation-duration: .3s; }
  .animated.bounceInDown {
    -webkit-animation-name: bounceInDown;
            animation-name: bounceInDown; }
  .animated.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
            animation-name: bounceOutUp; }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1); } }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }
  .rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8); }
  .rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: white;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden; }
    .rrt-confirm-holder .rrt-confirm .rrt-message {
      width: 100%;
      padding: 5%;
      min-height: 50px;
      font-size: 1em;
      background-color: white;
      text-align: center;
      font-family: 'open-sanscondensed-light', sans-serif;
      clear: both; }
    .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
      display: -webkit-flex;
      display: flex; }
      .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        height: 50px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        float: left;
        border-top: 1px solid #f0f0f0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
          background-color: #f5f5f5; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
          background-color: #60bb71;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
          background-color: #db6a64;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
          outline: none; }

body.toastr-confirm-active {
  overflow: hidden; }

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0; }

.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0; }

.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0; }

.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0; }

.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px; }

@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px; }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -160px; } }

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: .94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); }
  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    opacity: 1; }
  .redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px; }
    .redux-toastr .toastr .toastr-status.success {
      background-color: #60bb71; }
    .redux-toastr .toastr .toastr-status.warning {
      background-color: #f7a336; }
    .redux-toastr .toastr .toastr-status.info {
      background-color: #58abc3; }
    .redux-toastr .toastr .toastr-status.error {
      background-color: #db6a64; }
  .redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden; }
  .redux-toastr .toastr .rrt-left-container {
    width: 80px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0; }
    .redux-toastr .toastr .rrt-left-container .rrt-holder {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
      left: 5px;
      line-height: 60px; }
    .redux-toastr .toastr .rrt-left-container .toastr-icon {
      fill: white;
      vertical-align: middle;
      margin-top: 5px; }
  .redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 80px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px; }
    .redux-toastr .toastr .rrt-middle-container .rrt-title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px; }
  .redux-toastr .toastr .rrt-right-container {
    width: 10%; }
  .redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    font-size: 22px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial sans-serif; }
    .redux-toastr .toastr .close-toastr:hover {
      opacity: 1; }
    .redux-toastr .toastr .close-toastr:focus {
      outline: none; }
  .redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
    color: white; }
  .redux-toastr .toastr.rrt-info {
    background-color: #58abc3; }
    .redux-toastr .toastr.rrt-info .rrt-progressbar {
      background-color: #378298; }
  .redux-toastr .toastr.rrt-success {
    background-color: #60bb71; }
    .redux-toastr .toastr.rrt-success .rrt-progressbar {
      background-color: #3e914d; }
  .redux-toastr .toastr.rrt-warning {
    background-color: #f7a336; }
    .redux-toastr .toastr.rrt-warning .rrt-progressbar {
      background-color: #d87e09; }
  .redux-toastr .toastr.rrt-error {
    background-color: #db6a64; }
    .redux-toastr .toastr.rrt-error .rrt-progressbar {
      background-color: #c5352e; }
  .redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc; }
  .redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important; }
  .redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb; }
    .redux-toastr .toastr.rrt-message .rrt-title {
      width: 90%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 50px;
      padding: 0 20px; }
    .redux-toastr .toastr.rrt-message .rrt-text {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      background-color: white;
      padding: 15px;
      font-size: 1.1em;
      margin-bottom: 20px; }
      .redux-toastr .toastr.rrt-message .rrt-text img {
        display: block;
        margin: 10px auto;
        max-width: 100%; }
    .redux-toastr .toastr.rrt-message .close-toastr {
      height: 50px; }
  .redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
      border-radius: 0 0 0 4px;
      height: 100%; }

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }

.rrt-buttons-holder, .rrt-message, .rrt-confirm {
  background-color: var(--color-background) !important; }

.rrt-confirm-holder .rrt-confirm .rrt-message {
  font-size: 16px;
  line-height: 1.4em; }

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  border-top: 1px solid var(--color-separator);
  color: var(--color-text); }

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
  background-color: var(--color-1-light); }

