.header {
  flex: 0 0 auto;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
  transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  padding-right: 56px;
  &.fixed {
    position: fixed;
    // width: calc(100% - 65px);
    width: 100%;
    &.open {
      width: calc(100% - 230px);
    }
  }
  &__fake {
    width: 100%;
    flex: 0 0 auto;
  }
  &__title {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    // justify-content: space-between;
    align-items: center;
    gap: 8px;
    overflow: auto;
  }
  &__separator {
    background-color: var(--color-1-light);
    height: 32px;
    margin: 0px 5px;
    width: 1px;
    line-height: 56px;
    display: inline-block;
    vertical-align: middle;
  }
}

.color {
  &__radio:not(:last-child) {
    margin-bottom: 15px;
  }
}
