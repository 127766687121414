.data-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  &__title {
    font-size: 18px;
    line-height: 26px;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
  &__actions {
    margin-left: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__content {
    display: flex;
    align-content: stretch;
    width: 90%;
    justify-content: center;
  }
}
