$color-blue: var(--color-1);
$color-gray: #dfe0e4;
$color-white: #ffffff;
$slider-handle-size: 20px;
$slider-size: 4px;
$slider-fix: 20px;

.m-time {
  color: $color-white;
  padding-top: 50px;

  .showtime {
    text-align: center;
  }

  .separater {
    display: inline-block;
    font-size: 32px;
    font-weight: bold;
    color: $color-blue;
    width: 32px;
    height: 65px;
    line-height: 65px;
    text-align: center;
  }

  .time-text {
    position: relative;
    left: -10px;
    font-size: 15px;
    color: $color-blue;
    margin-top: 7px;
    margin-bottom: 10px;
  }
  .time-header-text {
    position: relative;
    left: 15%;
    top: -30px;
    //transform: translateX(-70%);
    font-size: 18px;
    text-transform: uppercase;
    color: $color-blue;
    margin-top: 7px;
    margin-bottom: 50px;
    padding-bottom: 50px;
    text-align: center;
  }

  .sliders {
    padding: 0 10px;
  }

  .time {
    width: 65px;
    height: 65px;
    display: inline-block;
    font-size: 38px;
    line-height: 65px;
    background-color: $color-blue;
    border-radius: 3px;
    text-align: center;
    border: 0;
    color: $color-white;
  }
}

.u-slider-time {
  position: relative;
  display: inline-block;
  background-color: $color-gray;
  border-radius: 3px;
  height: $slider-size;
  width: 100%;
  cursor: pointer;

  .value {
    position: absolute;
    background-color: $color-blue;
    border-radius: 3px;
    top: 0;
    height: 100%;
  }

  .handle {
    position: absolute;
    width: $slider-size;
    height: $slider-size;

    &:after {
      position: relative;
      display: block;
      content: '';
      top: -$slider-fix/2;
      left: -($slider-size + $slider-fix)/2;
      width: $slider-size + $slider-fix;
      height: $slider-size + $slider-fix;
      background-color: #fff;
      border: 3px solid $color-blue;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
