.copy-link {
  position:        relative;
  display:         inline-block;
  border-bottom:   1px dotted transparent;
  cursor:          pointer;
  padding:         0;
  margin:          0 10px;
  &:before {
    content:       '';
    display:       none;
    position:      absolute;
    top:           100%;
    left:          0;
    width:         100%;
    height:        20px;
    background:    transparent;
    z-index:       100;
  }
  &:hover {
    border-bottom: 1px dotted var(--color-1);
    color:         var(--color-1);
    z-index:       100;
    &:before {
      display:     block;
    }
  }
}
