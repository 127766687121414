.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  // margin-left: 65px;
  transition: margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &.open {
    margin-left: 230px;
  }
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

main {
  //height: 100%;
  flex: 1 1;
  overflow: hidden;
}

.main {
  overflow: auto;
  height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

.wrapper {
  height: 100%;
  opacity: 1;
  padding: 10px;
  transition: transform 0.25s ease 0.2s, opacity 0.25s ease 0.2s;
  &.anim-exit {
    transition: transform 0.25s ease 0s, opacity 0.25s ease 0s;
    transform: translate3d(0, 15px, 0px);
    opacity: 0;
  }
  &.anim-exit-active {
  }
  &.anim-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 15px, 0px);
  }
  &.anim-enter-active {
  }
}

.clippy {
  position: absolute;
  z-index: 999999;
}
