.widget {
  &__mobile-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding-top: 65px;
  }
  &__panel {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 56px;
    height: calc(100% - 56px);
    background-color: var(--color-background-f);
    z-index: 1299;
    // border-left: 1px solid var(--color-separator);
  }
  &__button {
    &:hover {
      background: var(--color-1-light);
    }
    &.animate {
      animation: animate 2.5s infinite ease-in-out;
    }
  }
}

@keyframes animate {
  0% {
    background: none;
  }
  50% {
    background: var(--color-1-light);
  }
  100% {
    background: none;
  }
}
