// Global css here

* {
  box-sizing: border-box;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: inherit;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root {
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
  //--color-1: #82b1ff; //main
  //--color-2: #f0f1f3; // bg
  //--color-3: #82b1ff; //checked
  //--color-side: #2e303c; // side menu
  //background-color: var(--color-2);
}

#root,
html {
  height: 100%;
}

body {
  font-size: 1rem;
}

html,
body {
  height: 100%;
  background-color: var(--color-2);
  color: var(--color-text);
}

h1 {
  font-weight: 500;
  font-size: 2.4rem;
}

[hidden] {
  display: none !important;
}

pre::-webkit-scrollbar,
textarea::-webkit-scrollbar,
nav::-webkit-scrollbar,
ul::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

pre::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
nav::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background: transparent;
}

pre::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
nav::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background: var(--color-separator);
  border: 1px solid #8c8c8c73;
  border-radius: 2px;
  margin-right: 1px;
  &:hover {
    background: var(--color-1-light);
  }
}

.link {
  text-decoration: underline;
  color: var(--color-1);
  cursor: pointer;
  &:hover {
    color: var(--color-1-light);
  }
}

.ReactModal__Overlay {
  z-index: 1900 !important;
}

.error {
  padding: 15px;
  margin: 10px;
  background-color: #ff000038;
  border-radius: 3px;
  width: 350px;
  
  p {
    padding: 0 0 10px 0;
    line-height: 1.2em;
  }
}

.widget {
  &__error {
    height: 55px;
    padding: 15px;
    background-color: #ff57575e;
    cursor: pointer;
  }

  &__button {
    position: relative;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

strong {
  font-weight: 600;
}

[data-tooltip] {
  // outline: red solid 1px; /*optional styling*/
  // font-size: 30px; /*optional styling*/
  
  position: relative;
  cursor: help;
}

[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
}
[data-tooltip]:hover::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;	
  border-bottom: 8px solid #000;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill,
input:-internal-autofill-selected {
  color: var(--color-text) !important;
  -webkit-text-fill-color: var(--color-text) !important;
}
