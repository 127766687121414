.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  &__card {
    min-width: 300px;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all .4s ease-in-out !important;
    transform: translate(-150px, -154px);
    &--show {
      transform: translate(-150px, -184px);
      opacity: .75;
    }
  }
  &__logo {
    width: 300px;
    padding: 10px 0 20px;
  }
  &__form {
    padding: 0 1em 1em 1em;
  }
  &__input {
    display: flex;
    input {
      &:-webkit-autofill {
        color: var(--color-text);
        -webkit-text-fill-color: var(--color-text);
      }
      &:-webkit-autofill:hover {
        color: var(--color-text);
        -webkit-text-fill-color: var(--color-text);
      }
    }
  }
  &__version {
    padding: 5px 10px 10px;
    font-size: 11px;
    color: var(--color-text);
    opacity: 0.9;
    span {
      float: right;
    }
  }
}

//valentine background
.dot {
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 2;
}

html,
body,
#container {
  width: 100%;
  height: 100%;
}

#container {
  position: absolute;
  top: 0;
}
