.rrt {
  &-buttons-holder,
  &-message,
  &-confirm {
    background-color: var(--color-background) !important;
  }
}

.rrt-confirm-holder .rrt-confirm .rrt-message {
  font-size: 16px;
  line-height: 1.4em;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  border-top: 1px solid var(--color-separator);
  color: var(--color-text);
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
  background-color: var(--color-1-light);
}
